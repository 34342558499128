import React, { useState } from "react";
import Image from "next/image";
import useTranslation from "next-translate/useTranslation";
import ModalVideo from "react-modal-video";
import Slider from "react-slick";

import icon from "@public/icons/icon-play-3.svg";

import { data } from "./mockup";

const FeedBack = () => {
  const { t } = useTranslation("feedback");

  const [isOpen, setOpen] = useState(false);
  const [detailVideo, setDetailVideo] = useState({});

  const settings = {
    autoplay: true,
    autoplaySpeed: 5000,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    dots: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 1367,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 577,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const handleOnClickPlay = (item) => {
    setDetailVideo(item);
    setOpen(true);
  };

  return (
    <div className="feedback">
      <div className="feedback__overlay" />
      <div className="feedback__header">
        <h1>{t("title")}</h1>
        <p>{t("content")}</p>
      </div>
      <div className="feedback__content">
        <Slider {...settings}>
          {data.map((item) => (
            <React.Fragment key={item.key}>
              <div className="feedback__content__item">
                <div
                  className="feedback__content__item--img"
                  onClick={() => handleOnClickPlay(item)}
                >
                  <Image
                    src={`http://img.youtube.com/vi/${item?.link}/0.jpg`}
                    alt="item"
                    layout="fill"
                  />
                  <div className="play">
                    <Image src={icon} alt="play" layout="fill" />
                  </div>
                </div>
                <p>{item.title}</p>
              </div>
            </React.Fragment>
          ))}
        </Slider>
      </div>

      {isOpen && (
        <ModalVideo
          channel="youtube"
          isOpen={isOpen}
          videoId={detailVideo?.link || ""}
          youtube={{ autoplay: 1 }}
          onClose={() => setOpen(false)}
        />
      )}
    </div>
  );
};

export default FeedBack;
