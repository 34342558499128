import React from "react";
import Tilt from "react-parallax-tilt";
import Link from "next/link";
import Image from "next/image";

import useTranslation from "next-translate/useTranslation";

import { PRODUCT } from "./mockup";

const Product = () => {
  const { t } = useTranslation("product");
  return (
    <div className="product">
      <div className="product__background"></div>
      <div className="product__content">
        <div className="product__content__header">
          <h1>{t("product")}</h1>
          <p>{t("content")}</p>
        </div>
        <div className="product__content__product">
          {PRODUCT?.map((i, index) => (
            <div className="product__content__product--wrapper" key={index}>
              <Tilt key={index}>
                <div className="product__content__product--item">
                  <Link href={i?.link || ""}>
                    <a target="_blank">
                      <Image
                        src={i?.image || ""}
                        alt="product"
                        layout="fill"
                      ></Image>
                    </a>
                  </Link>
                </div>
              </Tilt>
              <p>
                {t("set")} <span>{i?.name || ""}</span>
              </p>
              <div
                className="product__content__product--item--content"
                dangerouslySetInnerHTML={{ __html: i?.content }}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Product;
