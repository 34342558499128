import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import Image from "next/image";
import useTranslation from "next-translate/useTranslation";
import { Button } from "antd";
import Link from "next/link";

import { ASSETS_URL } from "configs/constant";
import { formatImage } from "@utils/helpers/logic/image";

// import { DATA_MOCKUP } from "./mockup";

const IntroBanner = ({ data }) => {
  const { t } = useTranslation("banner");

  const [dataBanner, setDataBanner] = useState([]);

  useEffect(() => {
    const isBannerArr = data?.docs?.filter((item) => item?.isBanner && item);
    const sortedArr = isBannerArr?.sort((a, b) => {
      return a?.bannerIndex - b?.bannerIndex;
    });

    setDataBanner(sortedArr);
  }, [data?.docs]);

  const settings = {
    // autoplay: true,
    autoplaySpeed: 5000,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    arrows: true,
    responsive: [
      {
        breakpoint: 577,
        settings: {
          arrows: false,
        },
      },
    ],
  };

  const renderSlogan = (slogan) => {
    const result = slogan
      ?.replaceAll("p>", "h2>")
      ?.replaceAll("/*", `<span class="white-text">`)
      ?.replaceAll("*/", "</span>");

    return `${result}`;
  };

  return (
    <div className="intro-banner">
      <Slider {...settings}>
        {dataBanner?.map((item) => (
          <React.Fragment key={item?.key}>
            <div className="intro-banner__content">
              <div className="intro-banner__content__background" />
              <div className="intro-banner__content__img">
                <Image
                  src={`${ASSETS_URL}/${formatImage(item?.image)}`}
                  alt="banner"
                  layout="fill"
                />
              </div>
              <div className="intro-banner__content__title">
                <div
                  dangerouslySetInnerHTML={{
                    __html: renderSlogan(item?.slogan || ``),
                  }}
                />
                <div
                  dangerouslySetInnerHTML={{
                    __html: `<p>${item?.description}</p>` || "",
                  }}
                />
                {item?.title && (
                  <Link href={`/news/${item?.titleSlug}`}>
                    <Button>{t("more")}</Button>
                  </Link>
                )}
              </div>
            </div>
          </React.Fragment>
        ))}
      </Slider>
    </div>
  );
};

export default IntroBanner;
