import request from "@libs/base/request";

const contactApi = async (data) =>
  request({
    url: "/v1/contact",
    data,
    method: "POST",
    tokenClient: true,
    msgSuccess: "successContact",
  });

export { contactApi };
