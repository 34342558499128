const data = [
  {
    id: 1,
    title: "định hướng tương lai",
    content: [
      "Trẻ em từ 10-18 tuổi",
      "Thời lượng 10 buổi học",
      " - ",
      "Nhóm học 4-6 trẻ em",
      "Chủ Động - Tự Tin - Ham Học",
    ],
  },
  {
    id: 2,
    title: "facilitator",
    content: [
      "Dành cho Người Hướng Dẫn",
      "Thời lượng 6 buổi học",
      "3 luật chơi cho người lớn",
      "Nhóm học 4-6 người",
      "Đào tạo tư duy tài chính",
    ],
  },
  {
    id: 3,
    title: "khai mở tư duy tài chính",
    content: [
      "Trên 20 tuổi",
      "Thời lượng 10 buổi học",
      "6 luật chơi",
      "Nhóm học 5-8 người",
      "Quản Lý Tiên và Đầu Tư Tiền",
    ],
  },
  {
    id: 4,
    title: "21 ngày thực chiến",
    content: [
      "Dành cho sinh viên và người mới ra trường",
      "21 buổi học và thực hành",
      "Học - Hành - Hạnh Phúc",
      "Nhóm học 4-6 người",
      "Đào tạo kinh doanh thực chiến",
    ],
  },
];

export { data };
