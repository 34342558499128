
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    
import { getNewsApi } from "@libs/apis/news";
import { getListFaqApi } from "@libs/apis/faq";
import { getVoucherApi, getMembershipApi } from "@libs/apis/product";

import Product from "@components/pages/home/Product";
import News from "@components/pages/home/News";
import Contact from "@components/pages/home/Contact";
import FeedBack from "@components/pages/home/FeedBack";
import CourseHome from "@components/pages/home/Course";
import IntroBanner from "@components/pages/home/IntroBanner";
// import Support from "@components/pages/home/Support";
// import Banner from "@components/pages/home/Banner";
// import BannerIntro from "@components/pages/home/BannerNew";

const HomePage = ({ listNews }) => {
  return (
    <div className="home">
      <IntroBanner data={listNews} />
      <Product />
      <News data={listNews} />
      <CourseHome />
      <FeedBack />
      {/* <Support data={listFqa} /> */}
      <Contact />
    </div>
  );
};

 async function _getServerSideProps() {
  const [getListNews, getListFaq, getListVoucher, getListMembership] =
    await Promise.all([
      getNewsApi({
        populate: "image.file, newsCategory",
        limit: 999,
      }),
      getListFaqApi(),
      getVoucherApi({
        populate: "image.file",
      }),
      getMembershipApi({
        populate: "image.file",
      }),
    ]);

  return {
    props: {
      listNews: getListNews?.data?.data || {},
      listFqa: getListFaq?.data?.data?.docs || [],
      dataCard: [
        ...getListVoucher?.data?.data?.docs,
        ...getListMembership?.data?.data?.docs,
      ],
    },
  };
}

export default HomePage;


    export async function getServerSideProps(ctx) {
        let res = _getServerSideProps(ctx)
        if(typeof res.then === 'function') res = await res
        return {
          
          ...res,
          props: {
            ...(res.props || {}),
            ...(await __loadNamespaces({
              ...ctx,
              pathname: '/index',
              loaderName: 'getServerSideProps',
              ...__i18nConfig,
              loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
            }))
          }
        }
    }
  