import React from "react";
import useTranslation from "next-translate/useTranslation";
import { Col, Row } from "antd";

import { data } from "./mockup";

const CourseHome = () => {
  const { t } = useTranslation("course-home");
  return (
    <div className="course-home">
      <div className="course-home__header">
        <h2>{t("title")}</h2>
        <p>{t("content")}</p>
      </div>

      <div className="course-home__content">
        <Row gutter={[20, 20]}>
          {data.map((item) => (
            <Col span={6} xl={6} lg={24} sm={24} xs={24} key={item.id}>
              <div className="course-home__content__item">
                <h3 className="course-home__content__item--title">
                  {item.title}
                </h3>
                <div className="course-home__content__item--info">
                  {item.content.map((content, index) => (
                    <p key={index}>{content}</p>
                  ))}
                  <div className="course-home__content__item--btn">
                    Xem thêm
                  </div>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </div>
    </div>
  );
};

export default CourseHome;
