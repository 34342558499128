import diamond from "@public/images/product-diamond.svg";
import berich from "@public/images/product-berich.svg";
import kylan from "@public/images/product-unicorn.svg";

const PRODUCT = [
  {
    key: 1,
    image: diamond,
    name: "Diamond",
    link: "https://beandiamond.com/",
    content: `<p>
        Hướng đến chữ <span className="highLightText">THÂN</span> </br> dùng để dạy kỹ
        năng cho trẻ từ 5-10 tuổi 
      </p>`,
  },
  {
    key: 2,
    image: berich,
    name: "Berich",
    link: "https://www.coberich.com/",
    content: `<p>
        Hướng đến chữ <span className="highLightText">TÂM</span> </br> dùng để dạy tư duy tài chính cho học sinh, sinh viên
      </p>`,
  },
  {
    key: 3,
    image: kylan,
    name: "UNICORN",
    link: "https://www.cokylan.com/",
    content: `<p>
        Hướng đến chữ <span className="highLightText">TRÍ</span> </br> dùng để dạy tư duy khởi nghiệp cho những ai mong muốn lập nghiệp, tự mình làm chủ
      </p>`,
  },
];

export { PRODUCT };
