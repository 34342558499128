const data = [
  {
    key: 1,
    title: "Cảm nhận học sinh",
    link: "z4XpEPrjjLs",
  },
  {
    key: 2,
    title: "Cảm nhận phụ huynh",
    link: "HGBjHs6usOI",
  },
  {
    key: 3,
    title: "Cảm nhận hiệu trưởng",
    link: "KWh2GuvNZWk",
  },
  {
    key: 4,
    title: "Cờ Diamond tại Mẫu giáo Hoa Mai",
    link: "iGkewKD7tV8",
  },
  {
    key: 5,
    title: "Clb doanh nhân Móng Cái",
    link: "tRhlvJQMm7U",
  },
];

export { data };
