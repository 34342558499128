import React from "react";
import { Button, Col, Form, Input, Row } from "antd";
import useTranslation from "next-translate/useTranslation";

import { regexPhone, rexgexEmail } from "@utils/regex";
import { contactApi } from "@libs/apis/contact";

const Contact = () => {
  const { t } = useTranslation("contact");
  const [form] = Form.useForm();
  const { TextArea } = Input;

  const handleSubmit = (data) => {
    const submitData = {
      content: data?.content,
      email: data?.contactEmail,
      name: data?.name,
      phone: data?.phone,
    };
    contactApi(submitData);
    form.resetFields();
  };

  return (
    <div className="contact-home">
      <div className="contact-home__header">
        <div className="contact-home__header__content">
          <h1>{t("title")}</h1>
        </div>
      </div>

      <div className="contact-home__container">
        <div className="contact-home__container__content">
          <Row gutter={32}>
            <Col lg={12} md={24} sm={24} xs={24}>
              <div className="contact-home__container__content--title">
                <h4>{t("contactNow")}</h4>
                <span>{t("contentContact")}</span>
              </div>

              <div className="contact-home__container__content--form">
                <Form form={form} onFinish={handleSubmit}>
                  <Row gutter={30}>
                    <Col span={12}>
                      <label className="title">
                        {t("fullName")} <span>*</span>
                      </label>
                      <Form.Item
                        name="name"
                        rules={[
                          {
                            required: true,
                            message: t("form.requiredName"),
                          },
                        ]}
                      >
                        <Input
                          className="input"
                          placeholder={t("placeholderName")}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <label className="title">
                        {t("phoneNumber")} <span>*</span>
                      </label>
                      <Form.Item
                        name="phone"
                        rules={[
                          {
                            required: true,
                            message: t("form.requiredPhone"),
                          },
                          {
                            pattern: new RegExp(regexPhone),
                            message: t("form.validPhone"),
                          },
                        ]}
                      >
                        <Input
                          className="input"
                          placeholder={t("placeholderPhone")}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row>
                    <Col span={24}>
                      <label className="title">
                        {t("email")} <span>*</span>
                      </label>
                      <Form.Item
                        name="contactEmail"
                        rules={[
                          {
                            required: true,
                            message: t("form.requiredEmail"),
                          },
                          {
                            pattern: new RegExp(rexgexEmail),
                            message: t("form.validEmail"),
                          },
                        ]}
                      >
                        <Input
                          className="input"
                          placeholder={t("placeholderEmail")}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row>
                    <Col span={24}>
                      <label className="title">{t("content")}</label>
                      <Form.Item name="content">
                        <TextArea
                          className="text-area"
                          maxLength={255}
                          rows={4}
                          placeholder={t("placeholderArea")}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Form.Item>
                    <Button className="btn" htmlType="submit">
                      {t("submit")}
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            </Col>
            <Col lg={12} md={24} sm={24} xs={24}>
              <div className="contact-home__container__content--location">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3919.2326580998088!2d106.66767541535195!3d10.793484661828685!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3175292c8776a8c7%3A0xcf1caee88679c8a1!2zMTg0LzFBIMSQLiBMw6ogVsSDbiBT4bu5LCBQaMaw4budbmcgMTAsIFBow7ogTmh14bqtbiwgVGjDoG5oIHBo4buRIEjhu5MgQ2jDrSBNaW5oIDcwMDAwLCBWaeG7h3QgTmFt!5e0!3m2!1svi!2s!4v1675150887672!5m2!1svi!2s"
                  width="100%"
                  height="100%"
                  style={{ border: 0 }}
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                  title="map"
                />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default Contact;
