import React from "react";
import useTranslation from "next-translate/useTranslation";
import Image from "next/image";
import Slider from "react-slick";
import Link from "next/link";

import { ASSETS_URL } from "configs/constant";

import { formatImage } from "@utils/helpers/logic/image";
import { formatDate } from "@utils/helpers/formatDate";

const News = ({ data }) => {
  const { t } = useTranslation("news");

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: Math.min(data?.docs?.length, 4),
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 576.01,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className="news">
      <div className="news__header">
        <h1>{t("title")}</h1>
        <p>{t("content")}</p>
      </div>
      <div className="news__content">
        <Slider {...settings}>
          {data?.docs?.map((i, index) => (
            <div className="news__content__item" key={index}>
              <Link href={`/news/${i?.titleSlug}`}>
                <a>
                  <div className="image">
                    <Image
                      src={`${ASSETS_URL}/${formatImage(i?.image) || ""}`}
                      alt="image"
                      layout="fill"
                      objectFit="cover"
                    />
                  </div>
                </a>
              </Link>

              <p>
                <span>{i?.newsCategory?.[0]?.name || ""}</span> -{" "}
                {i?.startDate
                  ? formatDate(i?.startDate, "DD/MM/YYYY")
                  : formatDate(i?.createdAt, "DD/MM/YYYY")}
              </p>

              <Link href={`/news/${i?.titleSlug}`}>
                <h4>{i?.title || ""}</h4>
              </Link>
              <Link href={`/news/${i?.titleSlug}`}>
                <a>{t("more")}</a>
              </Link>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default News;
